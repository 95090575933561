<template>
  <div class="container">
    <div>
      <b-img
        src="/scb/bn.png"
        alt="bannner"
        fluid
      />
    </div>

    <div>
      <div class="bg">
        <div>
          <b-img
            :src="`data:image/png;base64, ${Data.img}`"
            alt=""
            fluid
          />
        </div>
        <label for="code">กรุณาใส่โค้ด (Captcha) ให้ถูกต้อง</label>
        <br>
        <b-form-input
          v-model="Data.code"
          type="text"
          name="capcha"
        />
        <!-- <button class="btn">
          <i class="fas fa-redo-alt" />
          อัพเดท
        </button> -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mt-1"
          variant="outline-primary"
          size="sm"
          @click="Summit()"
        >
          Confirm
        </b-button>
        <!-- <div>
          <img
            :src="`data:image/png;base64, ${Data.img}`"
            alt=""
            height="50%"
          >
        </div> -->
      </div>
      <div class="bg2" />
    </div>
    <div
      class="text-right"
      style="margin-right: 4px"
    >
      <small>ธนาคารไทยพาณิชย์ จำกัด (มหาชน) 2553 @ สงวนลิขสิทธิ์</small>
    </div>
  </div>
</template>

<script>
import { BFormInput, BButton, BImg } from 'bootstrap-vue'

export default {
  components: { BFormInput, BButton, BImg },
  data() {
    return {
      Data: {
        code: null,
        img: null,
      },
    }
  },
  mounted() {
    this.GetData()
  },
  methods: {
    GetData() {
      this.$http
        .get(`getcapcha/${this.$route.params.ID}`)
        .then(response => {
          this.Data = response.data
        })
        .catch(error => console.log(error))
    },
    Summit() {
      const Obj = {
        code: this.Data.code,
      }
      this.$http
        .post(
          `storecode/${this.$route.params.ID}`,
          Obj,
        )
        .then(response => {
          this.Data = response.data
          this.name = ''
          this.Success('update สำเร็จ')
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.container {
  max-width: 640px;
  /* font-family: 'Roboto', sans-serif; */
}
.bg {
  background: #ededed;
  border: 0;
  padding: 16px;
  margin: 2px 5px;
}
.bg2 {
  background: #ededed;
  border: 0;
  height: 10px;
  margin: 2px 4px;
  border-radius: 0px 0px 7px 7px;
}
.top {
  position: absolute;
  z-index: 1;
  margin-top: -75px;
}
/* .img-right {
  float: right;
} */
/* .btn {
  border: 1px solid #000;
  padding: 6px 10px;
  font-size: 0.8rem;
}
.btn:hover {
  background: rgb(231, 231, 231);
  border: 1px solid #000;
  padding: 6px 10px;
  font-size: 0.8rem;
} */
.input-fm {
  margin-right: 2px;
  margin-bottom: 10px;
}
.img-ban {
  max-width: 1172px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
